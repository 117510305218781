import * as React from "react";
import { useEffect, useState } from 'react';
import { Cirriculum, Lesson } from './schema';
import { openLesson, errorLogger } from './misc';
import { get } from './requests';
import { ImageMapView } from './ImageMap';

interface CirriculumProps {
    match?: {
        params: {
            id: string;
            slug?: string;
        }
    };
    id?: number;
}

export function CirriculumView(props:CirriculumProps):JSX.Element {
    let id = props.id ?? props.match?.params.id;
    let [cirriculum, setCirriculum]:[Cirriculum, (cirriculum:Cirriculum) => void] = useState({} as Cirriculum);

    useEffect(() => {
        if (id) {
            get(`cirriculum/${id}`)
            .then((cirriculum:Cirriculum) => {
                console.log(cirriculum);
                setCirriculum(cirriculum);
            })
            .catch(errorLogger);

        } else {
            console.error("Missing cirriculum id");
        }
    }, []);

    if (!cirriculum?.id) {
        return <div id='CirriculumView' />;
    }

    if (cirriculum.imagemap) {
        let linkOptions = cirriculum.subjects.map((lesson) => {
            return {
                name: lesson.name,
                url: `/lesson/${lesson.id}`,
            }
        })
        return <ImageMapView imagemap={cirriculum.imagemap} linkOptions={linkOptions} />;
    }


    function findSubject(name:string):Lesson | null {
        console.log(cirriculum);
        let subjects:Array<Lesson> = cirriculum.subjects;

        if (subjects) {
            for (let lesson of subjects) {
                if (lesson.name === name) {
                    return lesson;
                }
            }
        }

        console.error(`Failed to find lesson: ${name}`);
        return null;
    }

    if (cirriculum.mode === "primary") {
        return (
            <div id='CirriculumView'>
                <div id='PrimaryMap'>
                    <div id='MapContainer'>
                        <div id='practical-life' onClick={openLesson(findSubject('Practical Life Records'))} />
                        <div id='sensorial' onClick={openLesson(findSubject('Sensorial Records'))} />
                        <div id='language' onClick={openLesson(findSubject('Language Records'))} />
                        <div id='math-and-science' onClick={openLesson(findSubject('Mathematics & Science Records'))} />
                        <div id='cultural' onClick={openLesson(findSubject('Cultural'))} />
                    </div>
                </div>
            </div>
        );
    }

    if (cirriculum.mode === "elementary") {
        return (
            <div id='CirriculumView'>
                <div id='ElementaryMap'>
                    <div id='MapContainer'>
                        <div id='arithmetic' onClick={openLesson(findSubject('Arithmetic'))} />
                        <div id='art' onClick={openLesson(findSubject('Art'))} />
                        <div id='biology' onClick={openLesson(findSubject('Biology Records'))} />
                        <div id='geography' onClick={openLesson(findSubject('Geography Records'))} />
                        <div id='geometry' onClick={openLesson(findSubject('Geometry Records'))} />
                        <div id='history' onClick={openLesson(findSubject('History Records'))} />
                        <div id='language' onClick={openLesson(findSubject('Language Records'))} />
                        <div id='music' onClick={openLesson(findSubject('Music Records'))} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            Error missing cirriculum
        </div>
    );
}
