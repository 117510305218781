import * as React from "react";
import * as data from './data';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { browserHistory } from './mmapHistory';

import { PreviousDailyVideos } from "./PreviousDailyVideos";
import { DailyVideo } from "./DailyVideo";
import { ManageFamilies } from "./admin";
import { ReviewWork } from "./teacher";
//import { Login } from "./Login";
import { StudentSelect } from "./StudentSelect";
//import { Maps } from "./Maps";
import { LessonView } from "./LessonView";
import { Home } from "./Home";
import { Main } from "./Main";
import { LandingMain } from "./landing/LandingMain";
import { CirriculumView } from "./CirriculumView";


export function PageNotFound():JSX.Element {
    return (
        <div style={{display: "flex", flex: "1", alignItems: "center", justifyContent: "center"}}>Page not found</div>
    );
}


export function routes():JSX.Element {
    let user = data.get('user');
    let student = data.get('student');
    (window as any)['user'] = user;
    (window as any)['student'] = student;

    if (!user) {
        //return <Login />;
        return (
            <Router history={browserHistory}>
                <LandingMain />
            </Router>
        );
    }

    if (!student && !user.is_teacher) {
        return <StudentSelect />;
    }

    return (
        <Router history={browserHistory}>
            <Main>
                <Switch>
                    <Route path="/login" render={() => <Redirect to={`/`}/>} />

                    <Route path="/cirriculum/:id" component={CirriculumView}/>
                    <Route path="/lesson/:id" component={LessonView}/>
                    <Route path="/lesson/:id/:slug" component={LessonView}/>
                    <Route path="/previous-daily-videos" component={PreviousDailyVideos}/>
                    <Route path="/daily-video/:date" component={DailyVideo}/>
                    <Route path="/review-work" component={ReviewWork}/>
                    <Route path="/families" component={ManageFamilies}/>

                    <Route path="/" component={Home} exact />
                    <Route path="/*" component={PageNotFound} />
                </Switch>
            </Main>
        </Router>
    );
}

//<Route path="/map" component={Maps}/>
