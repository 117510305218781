import * as React from "react";

export function ContactInformation(props:{}):JSX.Element {
    return (
        <div id='ContactInformation'>
            <p>
                For more information to enroll, please contact Ms. Griffin at mm@montessorimap.com.   Phone inquires are currently being answered by LEAP International Montessori School at 1 (505) 994-9433, Monday through Friday from 8:00am to 5:30pm, Mountain Daylight Time (GMT-6).
            </p>
        </div>
    );
}
