import * as React from "react";

export function Curriculum(props:{}):JSX.Element {
    return (
        <div id='Curriculum'>
            The curriculum for MontessoriMap follows the authentic Montessori curriculum as taught to Ms. Griffin in her Primary and Elementary trainings.  This training was obtained through the Association of Montessori Internationale and was intended for classroom use.  Ms. Griffin has adapted the lessons to be delivered in an on-line, virtual format.

            <div id='curriculum-entries'>
                <div className='curriculum-table-container'>
                    <div className='header'>
                        Primary (ages 3-6)
                    </div>
                    <PrimaryCurriculum />
                </div>
                <div className='curriculum-table-container'>
                    <div className='header'>
                        Elementary (ages 6-12)
                    </div>
                    <ElementaryCurriculum />
                </div>
            </div>
        </div>
    );
}

export function PrimaryCurriculum(props:{}):JSX.Element {
    return (
    <table className='curriculum-table'>
        <tr>
            <th>Practical Life</th>

            <td>
                <ul>
                    <li>Preliminary Movements</li>
                    <li>Care of the Environment</li>
                    <li>Care of the Person</li>
                    <li>Handwork</li>
                    <li>Other</li>
                    <li>Food</li>
                    <li>Social Relations</li>
                    <li>Music &amp; Rhythm</li>
                </ul>
            </td>
        </tr>

        <tr>
            <th>Sensorial</th>


            <td>
                <ul>
                    <li>Visual Sense: Dimension, Form, &amp; Color</li>
                    <li>Biology in the Sensorial</li>
                    <li>Tactile Sense</li>
                    <li>Auditory Sense</li>
                    <li>Gustatory Sense</li>
                    <li>Olfactory Sense</li>
                    <li>Stereognostic Sense</li>
                    <li>Visual Sense</li>
                </ul>
            </td>
        </tr>

        <tr>
            <th>Language</th>


            <td>
                <ul>
                    <li>Spoken Language</li>
                    <li>Biology Vocabulary</li>
                    <li>Geographic Terms</li>
                    <li>Written Language</li>
                    <li>Mechanical Reading</li>
                    <li>Reading Classification</li>
                    <li>Interpretive Reading</li>
                    <li>Reading Analysis</li>
                    <li>Word Study</li>
                    <li>Music Education</li>
                    <li>Expression with the Plastic Arts</li>
                    <li>Appreciations</li>
                    <li>Foreign Languages</li>
                    <li>Other</li>
                </ul>
            </td>
        </tr>

        <tr>
            <th>Math</th>


            <td>
                <ul>
                    <li>Numbers 0-10</li>
                    <li>The Decimal System</li>
                    <li>Continuation of Counting</li>
                    <li>Memorization (Operations) Work</li>
                    <li>Passage to Abstraction</li>
                    <li>Fractions</li>
                    <li>Time</li>
                    <li>Science Explorations</li>
                </ul>
            </td>
        </tr>

        <tr>
            <th>Cultural</th>


            <td>
                <ul>
                    <li>Land &amp; Water</li>
                    <li>Political Geography</li>
                    <li>Biomes</li>
                </ul>
            </td>
        </tr>

    </table>
    );
}

export function ElementaryCurriculum(props:{}):JSX.Element {
    return (
    <table className='curriculum-table'>
        <tr>
            <th>Arithmetic</th>
            <td>
                <ul>
                    <li>Passage to Abstraction</li>
                    <li>Multiples</li>
                    <li>Division</li>
                    <li>Fractions</li>
                    <li>Decimals</li>
                    <li>Squaring</li>
                    <li>Cubing</li>
                    <li>Square Root</li>
                    <li>Cube Root</li>
                    <li>Other Topics</li>
                    <li>Misc State Requirements</li>
                    <li>Computer Science</li>
                </ul>
            </td>
        </tr>

        <tr>
            <th>Art</th>

            <td>
                <ul>
                    <li>Introduction</li>
                    <li>The Elements</li>
                    <li>Principles of Design</li>
                    <li>Presentation of Elements with Different Media</li>
                    <li>Different Art Techniques with Different Media</li>
                    <li>Handwork</li>
                    <li>Other</li>
                </ul>
            </td>
        </tr>

        <tr>
            <th>Biology</th>

            <td>
                <ul>
                    <li>Introduction</li>
                    <li>Botany</li>
                    <li>Zoology</li>
                    <li>Human Anatomy</li>
                    <li>Classification</li>
                    <li>Ecology</li>
                </ul>
            </td>
        </tr>

        <tr>
            <th>Geography</th>

            <td>
                <ul>
                    <li>Creation of the Earth/ Idea of the Universe</li>
                    <li>Nature of the Elements</li>
                    <li>The Sun and the Earth</li>
                    <li>The Work of Air</li>
                    <li>The Work of Air</li>
                    <li>Human Geography</li>
                    <li>Astronomy</li>
                </ul>
            </td>
        </tr>

        <tr>
            <th>Geometry</th>

            <td>
                <ul>
                    <li>Introduction to Geometry</li>
                    <li>Congruency, Similarity &amp; Equivalence I &amp; II &amp;III</li>
                    <li>Polygons I &amp; II</li>
                    <li>Angles I &amp; II</li>
                    <li>Lines</li>
                    <li>Area</li>
                    <li>Circle I &amp; II</li>
                    <li>Solid Geometry</li>
                    <li>Addendums</li>
                    <li>Other</li>
                </ul>
            </td>
        </tr>

        <tr>
            <th>History</th>

            <td>
                <ul>
                    <li>Introduction</li>
                    <li>The Arrival of Humans</li>
                    <li>Presenting the History of Humans</li>
                    <li>Civilizations</li>
                    <li>A Romp Through Time</li>
                    <li>Locational Studies</li>
                    <li>Peace &amp; Tolerance Studies</li>
                    <li>Other</li>
                </ul>
            </td>
        </tr>

        <tr>
            <th>Language</th>

            <td>
                <ul>
                    <li>History of Language</li>
                    <li>Grammar &amp; Syntax: Introduction to Word Study</li>
                    <li>Grammar &amp; Syntax: Parts of Speech</li>
                    <li>Style</li>
                    <li>Logical/ Sentence Analysis</li>
                    <li>Written Language</li>
                    <li>Spoken Language</li>
                    <li>Literature</li>
                    <li>Foreign Languages</li>
                </ul>
            </td>
        </tr>

        <tr>
            <th>Music</th>

            <td>
                <ul>
                    <li>The Bells: Sensorial</li>
                    <li>The Bells: Notation</li>
                    <li>Singing</li>
                    <li>Listening</li>
                    <li>Movement</li>
                    <li>Rhythm</li>
                    <li>Tone Bars</li>
                    <li>Composers &amp; Musicians</li>
                    <li>Other</li>
                </ul>
            </td>
        </tr>
    </table>
    );
}
