import * as React from "react";
import * as data from './data';
import { Link } from 'react-router-dom';
import { Cirriculum } from './schema';
import { CirriculumView } from './CirriculumView';

export function Home():JSX.Element {
    let user = data.get('user');
    let cirriculum:Cirriculum;

    if (user.is_teacher) {
        console.log(user.classes);
        return (
            <div id='Home'>
                <div id='cirriculum-picker'>
                    <h3>Cirriculums</h3>
                    {user.classes.map((c) =>
                        <div key={c.id} id='cirriculum-option'>
                            <Link to={`/cirriculum/${c.cirriculum.id}`}>
                                {c.cirriculum.name}
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        );
    } else {
        let student = data.get('student');
        cirriculum = student.student_class.cirriculum;
        return (
            <div id='Home'>
                <CirriculumView id={cirriculum.id} />
            </div>
        );
    }
}
