import * as React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { get, put } from '../requests';
import { Work } from '../schema';
import { errorAlerter } from '../misc';
import { MediaThumbnail } from '../MediaThumbnail';

export function ReviewWork():JSX.Element {
    let [work_list, setWorkList]:[Array<Work>, (x:Array<Work>) => void] = useState([] as Array<Work>);

    useEffect(() => {
        get(`work/review`)
        .then((lst:Array<Work>) => {
            setWorkList(lst);
        })
        .catch(errorAlerter);
    }, []);


    return (
        <div id='ReviewWork'>
            {work_list.map((work:Work) => <WorkLine key={work.id} work={work} />)}
            {work_list.length === 0
                && <div className='no-work-to-review'>
                        <div>You're all caught up!</div>
                        <div>There's no more work to review right now.</div>
                   </div>
            }

        </div>
    );
}


function WorkLine(props:{work:Work}):JSX.Element {
    const work:Work = props.work;

    const [reviewed, setReviewed]:[boolean, (b:boolean) => void] = useState(false as boolean);

    const mark_reviewed = useCallback(() => {
        setReviewed(true);

        put(`work/${work.id}`, {'reviewed': true})
        .then((res:any) => {
            console.log('Reviwered', res);
        })
        .catch(errorAlerter);
    }, [work.id]);

    return (
        <div key={work.id} className={'Work-entry ' + (reviewed ? 'reviewed' : '')}>
            <div className='student-lesson-time'>
                <div className='student-name'>{work.student.name}</div>
                <div className='lesson-name'><Link to={`lesson/${work.lesson.id}/`}>{work.lesson.name}</Link></div>
                <div className='time'>{moment(work.created).fromNow()}</div>
            </div>

            <div className='media-thumbnail'>
                <MediaThumbnail media={work.media} />
            </div>

            <div className='review-button-container'>
                {!reviewed && <button onClick={mark_reviewed}>Mark as reviewed</button>}
            </div>
        </div>
    );
}
