import * as React from "react";
import { Stream } from "@cloudflare/stream-react";
import { Media } from './schema';


interface VideoPlayerProperties {
    playsInline?: boolean;
    autoPlay?: boolean;
    media: Media;
    noPlay?: boolean;
}


export function VideoPlayer(props:VideoPlayerProperties):JSX.Element {
    let media = props.media;

    console.log(media);
    if (media == null || !props.media.cf_uid) {
        return (
            <div className='video-missing'>
                No video
            </div>
        );
    }

    if (media.processing) {
        console.log(media.processing_state);
        let pct:number = parseFloat(media?.processing_state?.result?.status?.pctComplete || "-1");
        return (
            <div className='video-processing'>
                Video is currently being processed {pct >= 0 && <span> ({pct.toFixed(1)}%)</span>}
            </div>
        );
    }

    return (
        <Stream
            controls={!props.noPlay}
            src={props.media.cf_uid}
        />
    );

}


export function sources(media:Media | null, mime_type:string = ""):Array<JSX.Element> {
    let ret:Array<JSX.Element> = [];

    if (!media) {
        return ret;
    }

    if (media.variants) {
        media.variants.sort((a, b) => a.content_length - b.content_length);

        for (let v of media.variants) {
            if (v.mime_type?.indexOf(mime_type) >= 0) {
                ret.push(<source key={v.uuid} src={v.url} type={v.mime_type} />);
            }
        }
    }

    ret.push(<source key={media.uuid} src={media.url} type={media.mime_type} />);

    return ret;
}
