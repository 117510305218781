import * as React from "react";
import { useState, useEffect } from "react";
import * as data from './data';
import Sidebar from "react-sidebar";
import { Link } from "react-router-dom";
import { logout } from './misc';
import { Uploader } from './Uploader';

const mql = window.matchMedia(`(min-width: 768px)`);

/*** Layout our main view and routes ***/
export function Main(props:{children: React.ReactNode}):JSX.Element {
    let [sidebarOpen, _setSidebarOpen]:[boolean, (s:boolean) => void] = useState(false as any);
    let [sidebarDocked, setSidebarDocked]:[boolean, (s:boolean) => void] = useState(mql.matches);
    let [editing, setEditing]:[boolean, (s:boolean) => void] = useState(data.get('editing', false));

    let user = data.get('user');
    let student = data.get('student');
    const show_nav = (user && (user.is_teacher || user.is_staff)) || !!student;

    function resize() {
        setSidebarDocked(mql.matches);
        _setSidebarOpen(false);
    }

    useEffect(() => {
        mql.addListener(resize);

        return () => {
            mql.removeListener(resize);
        }
    }, []);


    function setSidebarOpen(tf:boolean):void {
        console.log(tf);
        _setSidebarOpen(tf);
    }

    function toggleEdit() {
        setEditing(!editing);
        data.set('editing', !editing);
    }


    return (
        <div id='MainContainer'>
            <Uploader />

            {show_nav
                ?
                <React.Fragment>
                    <Sidebar
                        open={sidebarOpen}
                        docked={sidebarDocked}
                        onSetOpen={setSidebarOpen}
                        sidebarId='Nav'
                        contentId='MainContent'
                        styles={{
                            dragHandle: {
                                top: '35px'
                            }
                        }}

                        touchHandleWidth={30}
                        sidebar={
                            <React.Fragment>
                              {user.is_teacher &&
                                  <div className={'fakelink ' + (editing ? 'editing' : '')} onClick={toggleEdit}>
                                      <i className='icon large icon-edit' />
                                      <div>Edit</div>
                                  </div>
                              }

                              <Link to='/'>
                                  <i className='icon large icon-home' />
                                  <div>Home</div>
                              </Link>


                              {user.is_teacher &&
                                  <Link to='/review-work'>
                                      <i className='icon large icon-progress' />
                                      <div>Student Work</div>
                                  </Link>
                              }

                              {user.is_admin &&
                                  <Link to='/families'>
                                      <i className='icon large icon-families' />
                                      <div>Families</div>
                                  </Link>
                              }

                              <div className='spacer' />

                              <div className='logout' onClick={logout}>Logout</div>
                            </React.Fragment>
                        }
                        >
                        {!sidebarDocked &&
                            <div id='NavTop'>
                                <i className='icon icon-bars' onClick={() => setSidebarOpen(true)} />
                            </div>
                        }

                        { props.children }
                    </Sidebar>
                </React.Fragment>
                :
                <div id='MainContent'>
                    {props.children}
                </div>
            }
        </div>
    );
}
