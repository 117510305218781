import * as React from "react";
//import * as data from "./data";
import { useState, useEffect } from "react";
import { errorAlerter } from '../misc';
import { get, post, put, del } from '../requests';
import { Class } from '../schema';


interface FamilyStudent {
        id: number;
        name: string;
        student_class: number;
}

interface Family {
    id: number;
    pin: string;
    email: string;
    last_name: string;

    students: Array<FamilyStudent>;
}


export function ManageFamilies():JSX.Element {
    let [familes, setFamiles]:[Array<Family>, (s:Array<Family>) => void] = useState([] as Array<Family>);
    let [classes, setClasses]:[Array<Class>, (s:Array<Class>) => void] = useState([] as Array<Class>);
    let [newPin, setNewPin]:[string, (s:string) => void] = useState('');
    let [newName, setNewName]:[string, (s:string) => void] = useState('');
    let [newEmail, setNewEmail]:[string, (s:string) => void] = useState('');

    const add_disabled = newPin.length < 4 || newName.length < 2 || newEmail.indexOf('@') <= 0;

    useEffect(() => {
        get('class/?limit=100')
        .then((res:any) => {
            setClasses(res.results);
        })
        .catch(errorAlerter);

        get('family/')
        .then((res:Array<Family>) => {
            setFamiles(res);
        })
        .catch(errorAlerter);
    }, []);


    function addFamily():void {
        post('family/', {
            'pin': newPin,
            'last_name': newName,
            'email': newEmail,
        })
        .then((res:Array<Family>) => {
            setNewPin('');
            setNewName('');
            setNewEmail('');
            setFamiles(res);
        })
        .catch(errorAlerter);
    }

    return (
        <div id='ManageFamilies'>
            <h2>Manage Families</h2>
            <div className='new-family'>
                <input className='pin' value={newPin} onChange={ev => setNewPin(ev.target.value)} placeholder='Pin'  />
                <input className='last_name' value={newName} onChange={ev => setNewName(ev.target.value)} placeholder='Last name'  />
                <input className='email' value={newEmail} onChange={ev => setNewEmail(ev.target.value)} placeholder='Email'  />
                <button onClick={addFamily} disabled={add_disabled}>Add Family</button>
            </div>

            {familes.map((family:Family) => <FamilyRow key={family.id} family={family} classes={classes} />)}

        </div>
    );
}

export function FamilyRow(props:{family:Family, classes: Array<Class>}):JSX.Element {
    const family = props.family;

    let [now, setNow]:[number, (s:number) => void] = useState(Date.now());
    let [pin, setPin]:[string, (s:string) => void] = useState(family.pin);
    let [last_name, setLastName]:[string, (s:string) => void] = useState(family.last_name);
    let [email, setEmail]:[string, (s:string) => void] = useState(family.email);
    let [deleted, setDeleted]:[boolean, (s:boolean) => void] = useState(false as boolean);

    let [students, setStudents]:[Array<FamilyStudent>, (s:Array<FamilyStudent>) => void] = useState(family.students);
    let [newName, setNewName]:[string, (s:string) => void] = useState('');
    let [newClass, setNewStudentClass]:[number, (s:number) => void] = useState(1);

    const changed = pin !== family.pin || last_name != family.last_name || email !== family.email;

    function save() {
        put(`family/${family.id}`, {
            'pin': pin,
            'last_name': last_name,
            'email': email,
        })
        .then(res => console.log('saved: ', res))
        .catch(errorAlerter);

        family.pin = pin;
        family.last_name = last_name;
        family.email = email;
        setNow(Date.now());
        if (now) {
            // ignore
        }
    }

    function addStudent() {
        post(`family/${family.id}`, {
            'name': newName,
            'student_class': newClass,
        })
        .then((students:Array<FamilyStudent>) => {
            family.students = students;
            setStudents(students);
            setNewName('');
        })
        .catch(errorAlerter);
    }

    function del_family() {
        if (confirm("Are you sure you want to remove " + family.pin + ' : ' + family.last_name + "?")) {
            del(`family/${family.id}`)
            .then((res:any) => console.log('removed: ', res))
            .catch(errorAlerter);

            setDeleted(true);
        }
    }

    if (deleted) {
        return null as unknown as JSX.Element;
    }

    return (
        <div className='Family-entry'>
            <input className='pin' type='number' value={pin} onChange={ev => setPin(ev.target.value)} placeholder='PIN' />
            <input className='last_name' value={last_name} onChange={ev => setLastName(ev.target.value)} placeholder='Last name' />
            <input className='email' type='email' value={email} onChange={ev => setEmail(ev.target.value)} placeholder='email' />
            {changed
                ? <button className='unsaved' onClick={save}>Save</button>
                : ( family.students.length === 0
                    ?  <button onClick={del_family}>Remove family</button>
                    : null
                )
            }

            <div className='Family-students'>
                {students.map((student:FamilyStudent) =>
                    <StudentRow key={student.id} family={family} student={student} classes={props.classes} />)}


                <div className='Family-Student-entry'>
                    <input className='name' value={newName} onChange={(ev) => setNewName(ev.target.value)} placeholder='Student' />
                    <select value={newClass} onChange={(ev) => setNewStudentClass(parseInt(ev.target.value))}>
                        {props.classes.map((cls:Class) => <option key={cls.id} value={cls.id}>{cls.name}</option>)}
                    </select>
                    <button onClick={addStudent} disabled={newName === ''} >Add Student</button>
                </div>
            </div>
        </div>
    );
}

export function StudentRow(props:{family:Family, student:FamilyStudent, classes: Array<Class>}):JSX.Element {
    const student = props.student;
    const family = props.family;

    let [now, setNow]:[number, (s:number) => void] = useState(Date.now());
    let [name, setName]:[string, (s:string) => void] = useState(student.name);
    let [deleted, setDeleted]:[boolean, (s:boolean) => void] = useState(false as boolean);
    let [student_class, setStudentClass]:[number, (s:number) => void] = useState(student.student_class);


    const changed = name !== student.name || student_class !== student.student_class;

    function save() {
        put(`family/${family.id}/${student.id}`, {
            'name': name,
            'student_class': student_class,
        })
        .then((res:any) => console.log('saved: ', res))
        .catch(errorAlerter);

        student.name = name;
        student.student_class = student_class;
        setNow(Date.now());
        if (now) {
            // ignore
        }
    }

    function del_student() {
        if (confirm("Are you sure you want to remove " + student.name + "?")) {
            del(`family/${family.id}/${student.id}`)
            .then((res:any) => console.log('removed: ', res))
            .catch(errorAlerter);

            family.students = family.students.filter(s => s.id !== student.id);
            setDeleted(true);
        }
    }

    if (deleted) {
        return null as unknown as JSX.Element;
    }

    return (
        <div className='Family-Student-entry'>
            <input className='name' value={name} onChange={(ev) => setName(ev.target.value)} placeholder='Student' />
            <select value={student_class} onChange={(ev) => setStudentClass(parseInt(ev.target.value))}>
                {props.classes.map((cls:Class) => <option key={cls.id} value={cls.id}>{cls.name}</option>)}
            </select>
            {changed
                ? <button className='unsaved' onClick={save} disabled={name === ''}>Save</button>
                : <button onClick={del_student}>Remove student</button>
            }
        </div>
    );
}
