import * as React from "react";
//import * as data from './data';
import { Route, Switch, Link } from "react-router-dom";
import { PageNotFound } from '../routes';
import { Login } from '../Login';
import { About } from './About';
import { Curriculum } from './Curriculum';
import { FAQ } from './FAQ';
import { Tuition } from './Tuition';
import { SchoolGroups } from './SchoolGroups';
import { ContactInformation } from './ContactInformation';
import { MontessoriMaterials } from './MontessoriMaterials';

/*** Layout our main view and routes ***/
export function LandingMain(props:{}):JSX.Element {
    return (
        <div id='LandingMain'>
            <NavBar />

            <div id='LandingContent-container'>
                <div id='LandingContent'>
                    <Switch>
                        <Route path="/login" component={Login}/>
                        <Route path="/about" component={About}/>
                        <Route path="/curriculum" component={Curriculum}/>
                        <Route path="/faq" component={FAQ}/>
                        <Route path="/tuition" component={Tuition}/>
                        <Route path="/school-groups" component={SchoolGroups}/>
                        <Route path="/contact" component={ContactInformation}/>
                        <Route path="/materials" component={MontessoriMaterials}/>

                        <Route path="/" component={About} exact />
                        <Route path="/*" component={PageNotFound} />
                    </Switch>
                </div>
            </div>
        </div>
    );
}

function NavBar(props:{}):JSX.Element {
    return (
        <React.Fragment>
            <div>
                <img id='logo' src='/assets/images/logo.png' />
            </div>

            <div id='NavBar'>
                <Link to='/about'>About</Link>
                <Link to='/curriculum'>Curriculum</Link>
                <Link to='/faq'>F.A.Q.</Link>
                <Link to='/tuition'>Tuition</Link>
                <Link to='/school-groups'>School Groups</Link>
                <Link to='/contact'>Contact</Link>
                <Link to='/materials'>Materials</Link>
                <Link to='/login' className='login'>Login</Link>
            </div>
        </React.Fragment>
    );
}
