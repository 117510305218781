import * as React from "react";
//import { useRef, useEffect} from "react";
import { Link } from 'react-router-dom';

export function PreviousDailyVideos():JSX.Element {
    return (
        <div id='PreviousDailyVideos'>
            {[ '2020-03-26', '2020-03-24', '2020-03-23', '2020-03-22', ].map(day =>
                <div key={day} className='entry'>
                    <span className='date'>{day}</span>
                    <Link to={`/daily-video/${day}`}>
                        <img src="/assets/videos/primary-dressing-frames-at-home.jpg" />
                    </Link>
                </div>
            )}
        </div>
    );
}
