import * as React from "react";
import * as data from "./data";
import { Link } from 'react-router-dom';
//import { useRef, useEffect} from "react";
import { useState, useEffect, useCallback} from "react";
import { get, put, post, del } from './requests';
import { Lesson, IdName, IdNameImage, Media, Work } from './schema';
import { errorLogger, errorAlerter, openLesson, slugify, media_url } from './misc';
import { useDropzone } from 'react-dropzone'
//import { image_resizer } from "./misc";
import { upload_file } from "./Uploader";
import { Spinner } from "./Spinner";
import { MediaThumbnail } from './MediaThumbnail';
import { VideoPlayer } from './VideoPlayer';
import { ImageMapView } from './ImageMap';

interface LessonProps {
    match: {
        params: {
            id: string;
            slug?: string;
        }
    }
}


export function LessonView(props:LessonProps):JSX.Element {
    const user = data.get('user', null);
    const student = data.get('student', null);
    const id = props.match.params.id;

    let [lesson, setLesson]:[Lesson, (lesson:Lesson) => void] = useState({} as Lesson);
    let [editing, setEditing]:[boolean, (e:boolean) => void] = useState(data.get('editing', false));
    let [spinner, setSpinner]:[boolean, (e:boolean) => void] = useState(false as any);
    let [mode, _setMode]:[string, (e:string) => void] = useState(lesson.mode || "video" as any);
    let [ct, setCt]:[number, (n:number) => void] = useState(1 as number);

    function onDrop(files:Array<File>) {
        console.log('video drop', files);
        if (files.length === 0) {
            alert("No suitable files");
            return;
        }
        if (files.length > 1) {
            alert("Too many files!");
            return;
        }
        try {
            let file = files[0];
            setSpinner(true);
            upload_file(file)
                .then((res:any) => {
                    let media:Media = res;
                    console.log("Upload successful", media);
                    put(`lesson/${id}`, {'video': media.uuid})
                        .then((res:any) => {
                            setSpinner(false);
                            lesson.video = media
                            setLesson(Object.assign({}, lesson) as Lesson);
                            console.log("Lesson video updated");
                        })
                        .catch(err => {
                            setSpinner(false);
                            errorLogger(err);
                            errorAlerter("Error uploading file");
                        });
                })
                .catch((err) => {
                    setSpinner(false);
                    errorLogger(err);
                    errorAlerter("Error uploading file");
                });
        } catch (err) {
            console.log(err);
        }
    }


    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: [
            'video/*',
        ],
        multiple: false,
        onDrop
    })

    useEffect(() => {
        data.watch('editing', setEditing, false, true);
        return () => {
            data.unwatch('editing', setEditing);
        }
    }, []);

    useEffect(() => {
        get(`lesson/${id}`)
        .then((lesson:Lesson) => {
            console.log(lesson);
            setLesson(lesson);
            _setMode(lesson.mode);
        })
        .catch(errorLogger);
    }, [id]);


    function setMode(mode:string):void {
        _setMode(mode);
        put(`lesson/${id}`, {'mode': mode})
        .then(() => {
            console.log('Mode updated');
        })
        .catch(errorLogger);
    }

    if (!lesson.id) {
        return <div id='LessonView' />;
    }

    let valid_sublessons = editing ? lesson.sublessons : lesson.sublessons.filter(s => (!!s.image || user.is_teacher));
    const underconstruction = !editing && valid_sublessons.length === 0 && !lesson.video;

    const linkOptions = lesson.sublessons.map((l) => {
        return {
            'name': `/lesson/${l.name}`,
            'url': `/lesson/${l.id}`,
        }
    });

    return (
        <div id='LessonView'>
            <div id='BreadCrumbs'>
                {lesson.breadcrumbs.map((cur:IdName) => (
                    <React.Fragment key={cur.id}>
                        <Link className='breadcrumb' to={`/lesson/${cur.id}/${slugify(cur.name)}`}>{cur.name}</Link>
                        <span className='gt'>&gt;</span>
                    </React.Fragment>
                ))}
                <span className='this-breadcrumb'>{lesson.name}</span>
                {editing &&
                    <select className='mode' value={mode} onChange={(ev) => setMode(ev.target.value)}>
                        <option value='video'>Video</option>
                        <option value='imagemap'>Image map</option>
                    </select>
                }
            </div>


            {mode === "video"
                ? <React.Fragment>
                    {underconstruction &&
                        <div className='underconstruction'>
                            <img src='/assets/images/under-construction.gif' />
                        </div>
                    }
                    {editing
                      ? <div className={'video-upload-container' + (isDragActive ? ' active' : '')} {...getRootProps()}>
                            <input {...getInputProps()} />
                            { lesson.video
                                ? <div className='video'>
                                      <div className='small-video'>
                                          <VideoPlayer
                                               playsInline
                                               media={lesson.video}
                                               />
                                      </div>
                                      To replace this video, click here or drag the video file within the dashed box.
                                   </div>
                                : <div className='video-missing'>
                                      To upload a video, click here or drag the video file within the dashed box.
                                  </div>
                            }
                            {spinner && <Spinner />}
                        </div>
                      : lesson.video
                         ? <div className='video'>
                               <VideoPlayer
                                    playsInline
                                    media={lesson.video}
                                    />
                            </div>
                         : <div className='video-missing'></div>
                    }

                    <div id='SubLessons'>
                        {valid_sublessons.map(sublesson => <SubLesson key={sublesson.id} sublesson={sublesson} />)}
                    </div>

                    {lesson.requires_work && student && <WorkArea lesson={lesson} />}
                  </React.Fragment>
                : <React.Fragment>
                    <ImageMapView imagemap={lesson.imagemap} linkOptions={linkOptions} onChange={(imagemap) => lesson.imagemap = imagemap}/>
                  </React.Fragment>
            }

            {editing && <SubLessonListEditor />}
        </div>
    );


    function SubLessonListEditor():JSX.Element {
        let [new_name, setNewName]:[string, (e:string) => void] = useState("");

        return (
            <div id='SubLessonListEditor'>
                <h1>Add, edit, or remove sublessons</h1>
                {lesson.sublessons.map((sublesson) => <SubLessonEditor key={sublesson.id} sublesson={sublesson} />)}
                <div>
                    <input value={new_name} placeholder="New lesson" onChange={(ev) => setNewName(ev.target.value)} />
                    <button onClick={(ev) => {
                        post(`/api/lesson/`, {
                            'parent_id': lesson.id,
                            'name': new_name,
                        })
                        .then((sublesson) => {
                            lesson.sublessons.push(sublesson);
                            setCt(ct + 1);
                        })
                        .catch((err) => errorAlerter(err));
                    }}>Add</button>
                </div>
            </div>
        );

        function SubLessonEditor({sublesson}:{sublesson:IdName}):JSX.Element {
            let [name, _setName]:[string, (e:string) => void] = useState(sublesson.name || "");

            function setName(name:string):void {
                _setName(name);
            }
            return (
                <div>
                    <input value={name} onChange={(ev) => {
                        sublesson.name = ev.target.value;
                        setName(ev.target.value);
                        put(`/api/lesson/${sublesson.id}`, {'name': sublesson.name})
                        .then(() => console.log("Sublesson updated"))
                        .catch((err) => errorAlerter(err));
                    }} />
                    <button onClick={(ev) => {
                        if (confirm(`Remove ${sublesson.name}?`)) {
                            del(`/api/lesson/${sublesson.id}`)
                            .then(() => console.log("Sublesson deleted"))
                            .catch((err:any) => {
                                errorAlerter("Failed to delete sublesson, there may be sub-sub lessons still present that need to be deleted first.");
                                errorLogger(err)
                            });
                            lesson.sublessons = lesson.sublessons.filter((l) => l.id !== sublesson.id);
                            setCt(ct + 1);
                        }
                    }}>Remove</button>
                </div>
            );
        }
    }
}

export function SubLesson(props:{sublesson: IdNameImage}):JSX.Element {
    let sublesson = props.sublesson;

    let [url, setUrl]:[string, (s:string) => void] = useState(
        sublesson.image ? media_url(sublesson.image, 256, 256) : '/assets/images/blank-map-small.png');
    let [editing, setEditing]:[boolean, (e:boolean) => void] = useState(data.get('editing', false));
    let [spinner, setSpinner]:[boolean, (e:boolean) => void] = useState(false as any);

    const onDrop = useCallback(files => {
        console.log("useCallback");
        console.log(files);
        if (!files || files.length == 0) {
            setSpinner(false);
            errorAlerter("No valid files selected");
            return;
        }

        try {
            let file = files[0];
            setSpinner(true);
            upload_file(file)
                .then((res:any) => {
                    let media:Media = res;
                    console.log("Upload successful", media);
                    put(`lesson/${sublesson.id}`, {'image': media.uuid})
                    .then((res:any) => {
                        setSpinner(false);
                        sublesson.image = media
                        setUrl(media_url(sublesson.image, 256, 256));
                        console.log('Set sublesson to ', sublesson);
                        console.log("Lesson image updated");
                    })
                    .catch(err => {
                        setSpinner(false);
                        errorLogger(err);
                    });
                })
                .catch(errorLogger);
        } catch (err) {
            console.log(err);
        }
    }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: [
            'image/*',
            //'video/*',
        ],
        multiple: false,
        onDrop
    })

    useEffect(() => {
        data.watch('editing', setEditing, false, true);
        return () => {
            data.unwatch('editing', setEditing);
        }
    }, []);

    if (editing) {
        return (
            <div className={'SubLesson editing ' + (isDragActive ? ' active' : '')} {...getRootProps()}>
                <input {...getInputProps()} />
                <img className='sublesson-image' src={url} />
                {spinner && <Spinner />}
                <div className='SubLesson-name'>
                    {sublesson.name}
                </div>
            </div>
        );
    } else {
        return (
            <div className={'SubLesson fakelink'} onClick={openLesson(sublesson)}>
                <img className='sublesson-image' src={url} />
                <div className='SubLesson-name'>
                    {sublesson.name}
                </div>
            </div>
        );
    }

}

export function WorkArea(props:{lesson: Lesson}) {
    const student = data.get('student', null);
    const lesson = props.lesson;

    let [spinner, setSpinner]:[boolean, (e:boolean) => void] = useState(false as any);
    let [work_list, setWorkList]:[Array<Work>, (e:Array<Work>) => void] = useState([] as Array<Work>);

    function onDrop(files:Array<File>):void {
        try {
            let file = files[0];
            setSpinner(true);
            upload_file(file)
                .then((res:any) => {
                    let media:Media = res;
                    console.log("Upload successful", media);
                    post(`work/${lesson.id}/${student.id}/`, {'media': media.uuid})
                    .then((work_list:Array<Work>) => {
                        setSpinner(false);
                        setWorkList(work_list);
                    })
                    .catch(err => {
                        setSpinner(false);
                        errorLogger(err);
                    });
                })
                .catch(errorLogger);
        } catch (err) {
            console.log(err);
        }
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: [
            'image/*',
            'video/*',
            'application/pdf',
            'text/plain',
        ],
        multiple: true,
        onDrop
    })

    useEffect(() => {
        get(`work/${lesson.id}/${student.id}/`)
        .then((work_list:Array<Work>) => {
            setWorkList(work_list);
        })
        .catch(errorLogger);
    }, [lesson.id]);

    return (
        <div id='WorkArea'>
            <div className={'Work-dropzone ' + (isDragActive ? ' active' : '')} {...getRootProps()}>
                <input {...getInputProps()} />
                <span>
                    <i className='icon icon-camera' />
                </span>
                <span>
                    Upload work
                </span>
                {spinner && <Spinner />}

            </div>

            <div className='WorkList'>
                {work_list.map(work => (
                    <div className='entry' key={work.id}>
                        <MediaThumbnail media={work.media} />
                    </div>
                ))}
            </div>
        </div>
    );
}


