
import * as React from "react";

export function MontessoriMaterials(props:{}):JSX.Element {
    return (
        <div id='MontessoriMaterials'>
            <p>
            As we have made most of our materials ourselves, we have a preference to helping other small, Montessori entrepreneurs.  If you have a material that you would to have featured on MontessoriMap.com, please let us know.
            </p>
        </div>
    );
}

