import * as React from "react";

export function Tuition(props:{}):JSX.Element {
    return (
    <div id='Tuition'>
        <p>
        $3000/ year per student
        </p>

        <p>
        $5000/ year per family (up to 5 students)
        </p>

        <p>
        $1000/ year for groups more than 5 students
        </p>

        <p>
        $7000/ year per Elementary student wanting to enroll in LEAP International Montessori School, LLC and be included in the live classroom environment
            <div className='note'>
                Art supplies, needed electronics, and Montessori materials not included in tuition.
            </div>
        </p>
    </div>
    );
}
