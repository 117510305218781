import * as React from "react";
import { useEffect, useState } from 'react';
import { TypedEventEmitter } from './TypedEventEmitter';
import { post } from './requests';
import { RectSpinner } from './RectSpinner';

interface Events {
    'upload-event': void;
}

let event_emitter = new TypedEventEmitter<Events>();
let last_upload_id:number = 1;
let uploads:{
    [id: string]: {
        name: string;
        uploaded: number;
        total: number;
    }
} = {};


export interface UploadFileResult {
    uuid: string;
    mime_type: string;
    content_length: number;
}

export function upload_file(file:File | Blob):Promise<Array<UploadFileResult>> {
    return new Promise<Array<UploadFileResult>>((resolve, reject) => {
        let upload_id = ++last_upload_id;

        let name = (file as any)?.name || 'Uploading...';

        uploads[upload_id] = {
            name: name,
            uploaded: 0,
            total: file.size,
        };

        function update_progress(direction: 'up'|'down', uploaded:number, total:number) {
            if (upload_id in uploads) {
                if (direction === 'up') {
                    uploads[upload_id].uploaded = uploaded;
                    uploads[upload_id].total = file.size;
                    event_emitter.emit('upload-event');
                }
                console.log(upload_id, "Progress: ", direction, uploaded, total);
            }
        }

        post("media/", file, update_progress)
        .then((res:any) => {
            delete uploads[upload_id];
            event_emitter.emit('upload-event');
            resolve(res);
        })
        .catch((err:any) => {
            delete uploads[upload_id];
            event_emitter.emit('upload-event');
            reject(err);
        })
    });
}


export function Uploader():JSX.Element {
    let [, setNow]:[number, (n:number) => void] = useState(1);

    useEffect(() => {
        function bump() {
            setNow(Date.now());
        }

        event_emitter.on('upload-event', bump);
        return () => {
            event_emitter.off('upload-event', bump);
        }
    }, [])

    let keys = Object.keys(uploads);
    console.log(keys);

    if (keys.length === 0) {
        return <div />;
    }

    return (
        <div id='Uploader' >
            {keys.map(key => (
                <div className='Uploader-entry'  key={key}>
                    <div className='Uploader-filename'>{uploads[key].name}</div>
                    <div className='Uploader-spinner'><RectSpinner /></div>
                    {uploads[key].total
                        ? <span>{humanFileSize(uploads[key].uploaded)} / {humanFileSize(uploads[key].total)} (
                            {(100.0 * uploads[key].uploaded / uploads[key].total).toFixed(1)} %
                        )</span>
                        : null
                    }
                </div>
            ))}
        </div>
    );
}

function humanFileSize(bytes:number, si=false, dp=1):string {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}
