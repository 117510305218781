import * as React from "react";


interface DailyVideoProps {
}

export function DailyVideo(props:DailyVideoProps):JSX.Element {
    /*
    return (
        <div id='DailyVideo'>
            <div className='video'>
                <VideoPlayer
                    playsInline
                    src="/assets/videos/primary-dressing-frames-at-home.mp4"
                    />
            </div>
        </div>
    );
    */
    return <div />;
}
