import * as React from "react";
import * as data from './data';
import { logout } from './misc';


export function StudentSelect():JSX.Element {
    let user = data.get('user');

    function setStudent(student:any) {
        data.set('student', student);
        window.location.reload();
    }

    return (
        <div id='StudentSelect'>
            <div id='StudentSelectBox'>
                {user.students.map((student:any) => (
                    <div className='student' key={student.id} onClick={(ev) => setStudent(student)}>
                        {student.name}
                    </div>
                ))}
                {user.students.length === 0 &&
                    <div>
                        No active students with this pin
                        <div>
                            <button onClick={logout}>Ok</button>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
