import * as React from "react";

export function FAQ(props:{}):JSX.Element {
    return (
    <div id='FAQ'>
        <div className='question'>
            Do students need to have previous Montessori experience in a classroom in order to be successful with MontessoriMap?
        </div>

        <div className='answer'>
            No.  Students can start MontessoriMap at any age or skill level, though previous experience (especially in a Primary classroom) is helpful.  Our intention was always to be able to offer as close as we could to an authentic Montessori education to those students who would not otherwise have the opportunity.
        </div>

        <div className='question'>
            Does the original Montessori curriculum follow a Map?
        </div>

        <div className='answer'>
            No, the map created for www.montessorimap.com was thought of by Ms. Griffin and the staff at LEAP International Montessori School, LLC as a fun way to organize the curriculum.  The map was based on ideas from existing children who were inspired by the Chronicles of Narnia.
        </div>

        <div className='question'>
            How will students “follow-up” with lessons without the original materials?
        </div>

        <div className='answer'>
            We are working on making materials and working with other teachers (and promoting them) who are working on creating on-line virtual tools.  We also offer (and sell) our hand-made Montessori materials in “kits” for an additional cost.  All lessons (as appropriate) contain links to websites where the materials can be purchased.  Also included (and promoted) are ideas for how much of the materials can be made or acceptable alternatives could be used.  Art and research options are also actively promoted- especially in the Elementary.
        </div>

        <div className='question'>
            How will students turn-in follow-up work?
        </div>

        <div className='answer'>
            Students may turn in follow-up work in any way that makes sense to them.  Scans, photos, videos, typed up reports, etc are considered to be only a starting point.  Creativity is strongly encouraged!  What is important is that students do follow-up work that is interesting to them; it is their education.
        </div>

        <div className='question'>
            Why are some lessons or subject islands “locked”?  How could they be opened?
        </div>

        <div className='answer'>
            The lessons are offered in a logical, holistic manner, with skill levels thought of.  For example, most lessons in History, Geography, Art, and Biology can be given to any student, at any age.  Lessons in Language, Arithmetic, Geometry, and Music can still be done with the interest of the students, but some “prerequisites” may be required before the children can progress in skills.  After all, most children need to know what a number quantity and value is before they can work on the squaring and cubing of numbers- right?  ;)
        </div>

        <div className='answer'>
        Like in the classroom, lessons are also offered to students in variety so they receive a well-rounded curriculum, but follow-up work is expected and required.  The lesson videos are not intended to be solely entertainment for students.  Rather, they are intended to be a source for ideas and concepts; the real and meaningful learning takes place when the students actually do something with the concepts presented to them in a way that is meaningful for them.
        </div>

        <div className='question'>
            Can I have 1 subscription for my whole classroom or school?
        </div>

        <div className='answer'>
            Unfortunately not.  Just as lessons are individualized for students in the classroom, for the students to move freely through the curriculum map, they need to have their own account.
        </div>

        <div className='question'>
            Where is MontessoriMap based out of?
        </div>

        <div className='answer'>
            MontessoriMap is its own school, but based out of the existing school, LEAP International Montessori School, LLC in Rio Rancho, NM USA.  Lessons are filmed either in the existing classrooms or in our filming studio.  All work examples were created from Ms. Griffin’s classroom or were donated from other Montessori classrooms.
        </div>

        <div className='question'>
            Can I download the videos to watch as much as I want?
        </div>

        <div className='answer'>
            Video lessons may not be downloaded, though they may be watched as much as the student wants, as long as their subscription to the school is active.
        </div>

        <div className='question'>
            Is there an option to join into an existing/ live classroom and interact with other students?
        </div>

        <div className='answer'>
            Yes!  There is an option to join into Ms. Griffin or Ms. Shelly’s live classroom.  In this case, the student would enroll in LEAP International Montessori School, LLC and would be personally followed by either Ms. Griffin or Ms. Shelly (for Primary only).  Live office hours are also included for personal observations, lesson questions, and smiles.  Ask us as about this!  J
        </div>

        <div className='question'>
            Is there a plan to expand the curriculum for adolescent students?
        </div>

        <div className='answer'>
            YES!  We are actively working on “expanding” the lands of our map now!  Stay tuned!
        </div>

        <div className='question'>
            Is there a plan to expand the lessons to be done in other languages?
        </div>

        <div className='answer'>
            YES!  We are actively working on this now!  Are you a trained Montessori teacher who speaks another language or do you know someone who is and would be interested in recording lessons?  Please have them contact us!
        </div>
    </div>
    );
}

