import * as React from "react";
import { useState } from "react";
import { Media } from './schema';
import { media_url } from './misc';
import { VideoPlayer } from './VideoPlayer';


export function MediaThumbnail(props:{media: Media}):JSX.Element {
    const media:Media = props.media;
    const img_url:string = media_url(media, 256, 256, 'image/jpeg')

    let [fullscreen, setFullScreen]:[boolean, (e:boolean) => void] = useState(false as any);

    function close(ev:any):void {
        console.log("Close");
        if (fullscreen) {
            ev.stopPropagation();
            setFullScreen(false);
        }
    }

    function open(ev:any):void {
        console.log("Open");
        if (!fullscreen) {
            ev.stopPropagation();
            setFullScreen(true);
        }
    }

    let full:JSX.Element | null = null;
    if (fullscreen) {
        full = (
            <div className='MediaThumbnail-fullscreen-view'>
                <div className='header' onClick={close}>
                    <i className='icon icon-close' />
                </div>

                {media.mime_type.indexOf('video/') >= 0
                    ? <VideoPlayer playsInline autoPlay media={media} />
                    : (
                        media.mime_type.indexOf('image/') >= 0
                            ? <img src={media.url} />
                            : <iframe src={media.url} />
                    )
                }
            </div>
        );
    }

    if (img_url === '') {
        return (
            <div className='MediaThumbnail' onClick={open}>
                {full}
                {media.filename}
            </div>
        )
    }

    return (
        <div className='MediaThumbnail'>
            {full}
            <img src={img_url} onClick={open} />
        </div>
    );
}
