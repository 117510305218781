import * as React from "react";
import * as data from './data';
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
const RegionSelect = require('react-region-select');
import { ImageMap, Media } from 'schema';
import { useDropzone } from 'react-dropzone'
import { Spinner } from "./Spinner";
import { put } from './requests';
import { upload_file } from "./Uploader";
import { errorLogger, errorAlerter, preload_image, media_url } from './misc';


//const WIDTH = 1920;
//const HEIGHT = 1080;

console.log(RegionSelect);

interface LinkOption {
    name: string;
    url: string;
}

interface ImageMapProps {
    imagemap: ImageMap;
    linkOptions: Array<LinkOption>;
    onChange?: (imagemap:ImageMap) => void;
}

interface Region {
    data: any;
    height: number;
    isChanging: boolean;
    'new': boolean;
    width: number;
    x: number;
    y: number;
}


export function ImageMapView(props:ImageMapProps):JSX.Element {
    const imagemap = props.imagemap;
    if (imagemap?.configuration?.regions) {
        for (let r of imagemap.configuration.regions) {
            delete r['new'];
            delete r['isChanging'];
        }
    }

    let [regions, _setRegions] : [Array<Region>, (s:Array<Region>) => void] = useState((imagemap?.configuration?.regions || []) as Array<Region>);
    let [editing, setEditing]:[boolean, (e:boolean) => void] = useState(data.get('editing', false));
    let [spinner, setSpinner]:[boolean, (e:boolean) => void] = useState(false as any);
    let [ct, setCt]:[number, (n:number) => void] = useState(1 as number);
    let [background_image, setBackgroundImage]:[string, (e:string) => void]
        = useState((imagemap.media && imagemap.media.length && media_url(imagemap.media[0], 1920, 1080)) || "");

    function save(_regions?:Array<Region>) {
        imagemap.configuration.regions = _regions || regions;
        if (_regions !== undefined) {
            _setRegions(_regions);
        }

        put(`imagemap/${imagemap.id}`, {
            'media': imagemap.media.map((m) => m.uuid),
            'configuration': {
                'regions': imagemap.configuration.regions
            }
        })
        .then((res:any) => {
            console.log("Imagemap updated");
        })
        .catch(err => {
            console.error(err);
        });

        setCt(ct + 1);

        if (props.onChange) {
            props.onChange(imagemap);
        }
    }

    function onDrop(files:Array<File>) {
        console.log('drop', files);
        if (files.length === 0) {
            alert("No suitable files");
            return;
        }
        try {
            setSpinner(true);
            let promises = [];
            for (let file of files) {
                let p = upload_file(file);
                p
                    .then((res:any) => {
                        const media:Media = res;
                        imagemap.media.push(media)
                        console.log("Upload successful", media);
                        save();
                    })
                    .catch((err) => {
                        errorLogger(err);
                        errorAlerter("Error uploading file");
                    });
                promises.push(p);
            }

            Promise.all(promises).then(() => setSpinner(false)).catch((err) => setSpinner(false));
        } catch (err) {
            console.log(err);
        }
    }


    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: [
            'image/*',
        ],
        multiple: true,
        onDrop
    })


    useEffect(() => {
        data.watch('editing', setEditing, false, true);
        return () => {
            data.unwatch('editing', setEditing);
        }
    }, []);


    function setRegions(regions:any) {
        _setRegions(regions);

        let isChanging = false;
        regions.map((r:Region) => isChanging ||= r.isChanging);
        if (isChanging) {
            return;
        }

        save();
        console.log(regions);
    }

    if (editing) {
        return (
            <div className='ImageMap editing' >
                {(imagemap.media.length > 0 || null) &&
                    <RegionSelect
                        className='RegionSelect'
                        maxRegions={100}
                        regions={regions}
                        onChange={setRegions}
                        regionRenderer={RegionDetailsMemo(imagemap, props.linkOptions, save)}>
                        <img src={background_image} />
                    </RegionSelect>
                }

                {(imagemap.media.length > 0 || null) &&
                    <div className='image-list'>
                            {imagemap.media.map((media:Media) =>
                                <div key={media.uuid}>
                                    {media.filename}
                                    <button className='remove' onClick={() => {
                                        if (confirm(`Really remove ${media.filename} from this image map?`)) {
                                            imagemap.media = imagemap.media.filter((m) => m.uuid !== media.uuid);
                                            save();
                                            setCt(ct + 1);
                                        }
                                    }}>Remove</button>
                                </div>
                            )}
                    </div>
                }

                <div className={"upload-area" + (isDragActive ? ' active' : '')}   {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div>
                    Click here to upload an image, or drag image files within the dashed area
                    </div>
                    {spinner && <Spinner />}
                </div>

            </div>
        );
    }



    imagemap.media.map((m) => preload_image(media_url(m, 1920, 1080)));

    return (
        <div className='ImageMap'>
            <div className='map-container'>
                <img src={background_image} />
                {(imagemap.configuration.regions || []).map((r:Region, idx:number) => {
                    if (!r.data.url) {
                        return null;
                    }

                    let media = imagemap.media.filter((m) => m.uuid === r.data.image)[0];

                    let style:any = {
                        left: r.x + '%',
                        top: r.y + '%',
                        width: r.width + '%',
                        height: r.height + '%',
                    };

                    if (media) {
                        return (
                            <Link key={idx} className='region'
                                style={style}
                                onMouseOver = {(ev) => setBackgroundImage(media_url(media, 1920, 1080))}
                                onMouseOut = {(ev) => setBackgroundImage(media_url(imagemap.media[0], 1920, 1080))}
                                to={r.data.url}
                            />
                        );
                    } else {
                        return (
                            <Link key={idx} className='region highlight'
                                style={style}
                                to={r.data.url}
                            />
                        );
                    }
                })}
            </div>
        </div>
    );
}


/*
function open(url):(ev) => void {
    return (ev) => {
        window.location = url;
    }
}
*/


function RegionDetailsMemo(imagemap:ImageMap, linkOptions:Array<LinkOption>, save:(_regions?:Array<Region>) => void):(data:any, isChanging:boolean) => JSX.Element {
    return (data:any, isChanging:boolean):JSX.Element => {
        const image = data.data.image || 'highlight';
        const url = data.data.url;

        console.log(data, isChanging);
        if (isChanging) {

            return <div/>
        }

        return (
            <React.Fragment>
                <div className='RegionOverlay' />
                <div className='RegionDelete' onClick={() => {
                    let regions = imagemap.configuration.regions;
                    regions.splice(data.index, 1);
                    save(regions);
                }}>
                </div>
                <div className='RegionDetails'>
                    <select value={image} onChange={(ev:any) => {
                        imagemap.configuration.regions[data.index].data.image = ev.target.value;
                        data.data.image = ev.target.value;
                        console.log(ev.target.value);
                        save();
                    }}>
                        <option key={'highlight'} value={'highlight'}>Highlight</option>
                        {imagemap.media.map((media:Media) =>
                            <option key={media.uuid} value={media.uuid}>{media.filename}</option>
                        )}
                    </select>
                    <select
                        value={url}
                        onChange={(ev:any) => {
                            imagemap.configuration.regions[data.index].data.url = ev.target.value;
                            data.data.url = ev.target.value;
                            console.log(ev.target.value);
                            save();
                        }}
                    >
                        <option key={'none'} value={''}>-- Destination --</option>
                        {linkOptions.map((opt:LinkOption) =>
                            <option key={opt.url} value={opt.url}>{opt.name}</option>
                        )}
                    </select>
                </div>
            </React.Fragment>
        );
    };
}
