import * as React from "react";

export function SchoolGroups(props:{}):JSX.Element {
    return (
    <div id='SchoolGroups'>
        <p>
        MontessoriMap.com is not intended to replace a “brick and mortar” classroom when one is available.  In response to the World Health Events, we do have an option for school groups to use MontessoriMap.com to give students the option of seeing individual lessons and working their way through materials at their own pace, but still be involved with their own (local) community and classroom.
        </p>

        <p>
        In this case:

        <ul>
            <li>The student’s (local/ regular) teacher would be the one meeting with the students to help with questions, build (local) community through holding literature circles, etc. </li>

            <li>We believe this is an excellent tool for schools to still be able to maintain the integrity of their communities, keep children enrolled, and even be able to keep teachers at home (although they would be away from their classroom with materials). </li>

            <li>Tuition would be $1000/year per student ($100/ month for the 10 month school year).  Each student would need to have their own account.  (Non-negotiable).</li>
        </ul>
        </p>
    </div>
    );
}
