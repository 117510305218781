import * as React from "react";

export function About(props:{}):JSX.Element {
    return (
        <div id='About'>
            <p>
                www.MontessoriMap.com is a distance learning, Montessori based curriculum for children aged approximately 3-12 years old.  The lessons offered are the traditional Montessori lessons, as taught during Montessori training and would be offered in any traditional Montessori school.  Because of the distance format, additional lessons have also been recorded to meet the needs of the distance learner, as well as personal and professional interests of teachers.
            </p>

            <p>
                Both the “Primary” (intended for children ages 3-6 year old) and the “Elementary” (intended for children ages 6-12 years old) are taught by Ms. Sheila A. Griffin, M.Ed., a Montessori trained teacher through the Association of Montessori Internationale (AMI), and small school owner at LEAP International Montessori School, LLC in Rio Rancho, NM USA.
            </p>

            <p>
                Ms. Griffin has lead taught a combined classroom of Primary and Elementary students for 6 years and taught at the Elementary level for several years overseas and in the United States since 2008.
            </p>
        </div>
    );
}
