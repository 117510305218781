import * as data from './data';
import {deepCompare} from "./misc";


export function apiify(path:string):string {
    if (path.indexOf("/api/v") === 0) {
        return path;
    }
    if (path.indexOf("/") === 0) {
        return path;
    }
    if (path.indexOf("://") > 0) {
        return path;
    }

    return `/api/${path}`;
}

export const api = apiify;

let initialized = false;
function initialize():void {
    if (initialized) {
        return;
    }
    initialized = true;

    function csrfSafeMethod(method:string):boolean {
        return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
    }
    function getCookie(name:string):string | null {
        let cookieValue = null;
        if (document.cookie && document.cookie !== "") {
            let cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                let cookie = jQuery.trim(cookies[i]);
                if (cookie.substring(0, name.length + 1) === (name + "=")) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
    $.ajaxSetup({
        crossDomain: false, // obviates need for sameOrigin test
        beforeSend: (xhr, settings) => {
            let user = data.get('user')

            if (!csrfSafeMethod(settings.type ?? '')) {
                if (true) {
                    xhr.setRequestHeader("X-CSRFToken", getCookie("csrftoken") ?? '');
                }
            }

            if (user && user.token) {
                xhr.setRequestHeader("Authorization", `Token ${user.token}`);
            }
        }
    });
}


let requests_in_flight:{[id:string]: any} = {};
let last_request_id: number = 0;
type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

interface RequestFunction {
    (url: string): Promise<any>;
    (url: string, data: any): Promise<any>;
    (url: string, data: any, progress_cb: (direction:'up'|'down', progress: number, total:number) => void): Promise<any>;
}

export function request(method: Method): RequestFunction {
    return (url: string, data?: any, progress_cb?: (direction:'up'|'down', loaded: number, total:number) => void ) => {
        initialize();


        let real_url: string = url;
        let real_data: any = data;
        real_data = data;

        for (let req_id in requests_in_flight) {
            let req:any = requests_in_flight[req_id];
            if (req.promise && (req.url === real_url) && (method === req.type) && deepCompare(req.data, real_data)) {
                //console.log("Duplicate in flight request, chaining");
                return req.promise;
            }
        }

        let request_id = ++last_request_id;
        let traceback = new Error();

        requests_in_flight[request_id] = {
            type: method,
            url: real_url,
            data: real_data,
        };


        requests_in_flight[request_id].promise = new Promise((resolve, reject) => {
            let opts: {[id:string]: any} = {
                url: apiify(real_url),
                type: method,
                dataType: "json",
                contentType: "application/json",
                success: (res:any) => {
                    delete requests_in_flight[request_id];
                    resolve(res);
                },
                xhr: () => {
                    let xhr = new window.XMLHttpRequest();
                    //Upload progress, request sending to server
                    if (progress_cb) {
                        progress_cb('up', 0, 0);
                        progress_cb('down', 0, 0);
                    }
                    xhr.upload.addEventListener("progress", (evt) => {
                        if (progress_cb) {
                            progress_cb('up', evt?.loaded || 1, evt?.total || 1);
                        }
                    }, false);
                    //Download progress, waiting for response from server
                    xhr.addEventListener("progress", (evt) => {
                        if (progress_cb) {
                            progress_cb('down', evt?.loaded || 1, evt?.total || 1);
                        }
                    }, false);
                    return xhr;
                },
                error: (err:any) => {
                    delete requests_in_flight[request_id];
                    if (err.status !== 0) { /* Ignore aborts */
                        console.warn(apiify(real_url), err.status, err.statusText);
                        console.warn(traceback.stack);
                    }
                    console.error(err);
                    reject(err);
                }
            };
            if (real_data) {
                if ((real_data instanceof Blob) || (Array.isArray(real_data) && real_data[0] instanceof Blob)) {
                    opts.data = new FormData();
                    if (real_data instanceof Blob) {
                        opts.data.append("file", real_data);
                    } else {
                        for (let file of (real_data as Array<Blob>)) {
                            opts.data.append("file", file);
                        }
                    }
                    (opts as any).processData = false;
                    (opts as any).contentType = false;
                } else {
                    if (method === "GET") {
                        opts.data = real_data;
                    } else {
                        opts.data = JSON.stringify(real_data);
                    }
                }
            }

            requests_in_flight[request_id].request = $.ajax(opts);
        });

        return requests_in_flight[request_id].promise;
    };
}

export const get = request("GET");
export const post = request("POST");
export const put = request("PUT");
export const patch = request("PATCH");
export const del = request("DELETE");

export function abort_requests_in_flight(url:string, method?: Method) {
    for (let id in requests_in_flight) {
        let req = requests_in_flight[id];
        if ((req.url === url) && (!method || method === req.type)) {
            req.request.abort();
        }
    }
}

