import * as React from "react";
import { useState, useEffect, ChangeEvent } from "react";
import { put, get } from './requests';
import * as data from './data';
import { errorAlerter, deepCompare } from './misc';
import { Student } from './schema';


function refresh_user():void {
    get('auth/login')
    .then(res => {
        if (!deepCompare(data.get('user'), res)) {
            data.set('user', res);
            let user = res;
            if (user.students.length === 1) {
                data.set('student', user.students[0]);
            } else {
                let student = data.get('student');
                if (student) {
                    console.log(student);
                    try {
                        let new_student = user.students.filter((s:Student) => s.id === student.id)[0];
                        if (new_student) {
                            console.log("Updating student", new_student);
                            data.set('student', new_student);
                        }
                    } catch (err) {
                        errorAlerter(err);
                    }
                }
            }
            window.location.reload();
        }
    })
    .catch(err => {
        console.log(err);
    });
}

if (data.get('user')) {
    refresh_user();
}


export function Login():JSX.Element {
    let [pin, _setPin] = useState('');
    let [err, _setErr] = useState(false);

    function setPin(ev:ChangeEvent<HTMLInputElement>) {
        let pin = ev.target.value;
        _setErr(false);

        if (pin.length >= 4) {
            put('auth/login', {pin})
            .then(res => {
                console.log(res);
                data.set('user', res);
                let user = res;
                if (user.students.length === 1) {
                    data.set('student', user.students[0]);
                }

                window.location.reload();
            })
            .catch(err => {
                console.log(err);
                _setErr(true);
            });
        }

        _setPin(pin);
    }

    useEffect(() => {
        document.getElementById('Pin')?.focus();
    });

    return (
        <div id='Login'>
            <div id='LoginBox'>
                <input id='Pin'
                    type='number'
                    className={err ? 'error' : ''}
                    value={pin}
                    onChange={setPin}
                    placeholder='Enter PIN'
                    maxLength={4}
                />
            </div>

        </div>
    );
}

/*
            <div id='Bottom-wordpress-link'>
                <a href='/wp/'>More information</a>
            </div>
            */
